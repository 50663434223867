<template>
  <win-block>
    <template #win-title-left>Покупки</template>

    <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="10"
        :loading="loadingTable"
    >
      <template v-slot:item.create="{ item }">
        <span>{{ new Date(item.create).toLocaleString() }}</span>
      </template>
    </v-data-table>
  </win-block>
</template>

<script>

export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
  },
  data() {
    return {
      loadingTable: true,
      headers: [
        {text: 'Дата', value: 'create'},
        {text: 'Название', value: 'prod_info.name',},
        {text: 'Состояние', value: 'status_text',},
        {text: 'Примечания', value: 'comment',},
      ],
      desserts: [],
    }
  },
  mounted: function () {
    this.$bus.on("shop-orders-update", this.initialize);
  },
  beforeDestroy: function () {
    this.$bus.off("shop-orders-update", this.initialize);
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.$axios.get(this.$url.shop_user_orders)
          .then(response => {
            this.desserts = response.data
            this.loadingTable = false
          })
    }
  }
}
</script>


<style scoped>

</style>