<!-- https://progi.pro/dannie-i-metodi-komponentov-vue-ischezayut-na-odnom-elemente-pri-vizualizacii-s-pomoshyu-v-for-v-kachestve-kart-vuetify-6429981-->
<template>
  <win-block>
    <template #win-title-left>Витрина</template>

    <v-container fluid>
      <v-row dense>
        <v-col
            v-for="item in items"
            :key="item['id']"
            cols="12" sm="6" lg="4"
        >
          <v.product :product="item" @show="dialogBuy"></v.product>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog_buy_open" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            <div>{{ $t("Приобрести:") }}</div>
            <div>{{ product.name }}</div>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog_buy_open=false">Cancel</v-btn>
            <v-btn color="blue darken-1" :loading="loading" text @click="closeBuy">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>
  </win-block>
</template>

<script>


// import Product from "@/views/shop/user/product";

export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
    "v.product": () => import('./products-item'),
  },
  data: () => ({
    snackbar_show: false,
    snackbar_text: '',
    loading: false,
    product: {},
    dialog_buy_open: false,
    items: [],
  }),
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.$axios.get(this.$url.shop_user_products)
          .then(response => {
            this.items = response.data
            this.loadingTable = false
          })
    },
    dialogBuy(product) {
      this.product = product
      this.dialog_buy_open = true
    },
    closeBuy() {
      this.loading = true
      this.$axios.post(this.$url.shop_user_orders, {"product": this.product.id})
          .then(response => {
            response
            this.loading = false
            this.dialog_buy_open = false;
            this.$bus.emit('shop-balance-update');
            this.$bus.emit('shop-orders-update');
            this.$bus.emit('snackbar', {
              text: 'Для получения товаров постройте контейнер и<br/>' +
                  'в игровой чат введите команду <b>!sewa shop</b><br/>' +
                  'Спасибо за покупку! <a href="https://spaceengineers.world/terranova/контейнер-магазина" target="_blank">Подробнее</a>',
              timeout: 20000
            });
            // this.snackbar_text = this.$t("спасибо за покупку")
            // this.snackbar_show = true
          })
      // .catch(error => {
      //   this.loading = false;
      //   this.snackbar_text = error.response.data['detail']
      //   this.snackbar_show = true
      //   // window.console.log(error.response);
      //   return Promise.reject(error);
      // })
    },
  }

}
</script>

<style scoped>

</style>

<i18n>
{
"ru":{
"Must be valid e-mail":"Введите правильную почту",
"E-mail is required":"Почта обязательна"
}
}
</i18n>