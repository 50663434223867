<template>
  <v-row no-gutters justify="center">
    <v-col cols="10">
        <products></products>
    </v-col>
    <v-col cols="8">
        <buy></buy>
    </v-col>
  </v-row>
</template>

<script>
import products from "./products";
import buy from "./table-orders";


export default {
  components: {products, buy},
}
</script>

<style scoped>

</style>